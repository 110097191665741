import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../../state/app';
import { RichText } from '../../richTextOptions';

const VetsOurTeamArticle = ({ article, isPreview }) => {
     const { employeeName, employeeRole, employeeAvatar, employeeLongDescription } = isPreview ? article.fields : article;
     const featuredImage = isPreview ? employeeAvatar.fields.file.url :employeeAvatar.file.url;
     
     return (
          <>
               <div className="vets-ourteam-article-detail">
                    <div
                         className="vets-ourteam-article-detail-avatar"
                         style={{
                              backgroundImage: `url(${featuredImage})`,
                              width: '100px',
                              height: '100px'
                         }}
                    ></div>

                    {employeeName && (
                         <div className="vets-ourteam-article-detail-title">
                              <h1>{employeeName}</h1>
                              <p>{employeeRole}</p>
                         </div>
                    )}
               </div>

               {employeeLongDescription && (
                    <div className="article-page-body">
                         <RichText data={employeeLongDescription} />
                    </div>
               )}
          </>
     );
};

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

VetsOurTeamArticle.propTypes = {
     isPreview: PropTypes.bool
};

const ContentfulVetsOurTeamArticle = connect(mapStateToProps)(VetsOurTeamArticle);

export default ContentfulVetsOurTeamArticle;
